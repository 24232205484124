import React from "react";
import '../css/speaker.css';
import { Link } from "@mui/material";

const CFP = () =>{
    return(
        <div className="window-main">
            <div className="window-sp">
                <div className="speaker-content">
                    <div style={{padding:"2rem"}}>
                        <h1>Call For Paper: BSides Mumbai 2024</h1>
                        <p>
                            NOTE: <strong style={{color:"#e56c61"}}>BSides Mumbai 2024</strong> is an in person event. If your submission is accepted, you will be required to deliver your session in-person at our venue <strong style={{color:"#e56c61"}}>(TBD)</strong>,Mumbai on 25th May 2024.
                        </p>
                        <p>
                                Welcome to the 1st Edition of BSides Mumbai.
                                Security BSides is an annual security conference. The main focus of the conference is to showcase the next generation of offensive and defensive security technology. We happily open doors to researchers and hackers around the world to work on the 'next big thing' in security. We request everyone to submit their new research.
                        </p>
                    </div>

                    <div className="card-main">
                    <div className="card2">
                        <div className="card2-head">
                            <strong >Important Dates</strong>
                        </div>
                        <div className="card2-info">
                            <div className="det">
                                <div><p>CFP opens</p></div>
                                <div className="date"><strong style={{color:"#e56c61"}}>16th March</strong></div>
                            </div>
                            <div className="det">
                                <div><p>CFP closes</p></div>
                                <div className="date"><strong style={{color:"#e56c61"}}>31st March</strong></div>
                            </div>
                            <div className="det">
                                <div><p>First round acceptance notices will be issued</p></div>
                                <div className="date"><strong style={{color:"#e56c61"}}>8th April</strong></div>
                            </div>
                            <div className="det">
                                <div><p>Accepted candidates must confirm acceptance before</p></div>
                                <div className="date"><strong style={{color:"#e56c61"}}>10th April</strong></div>
                            </div>
                            <div className="det">
                                <div><p>Speaker Announcement</p></div>
                                <div className="date"><strong style={{color:"#e56c61"}}>12th April</strong></div>
                            </div>
                            <div className="det">
                                <div><p>Conference Date</p></div>
                                <div className="date"><strong style={{color:"#e56c61"}}>25th May</strong></div>
                            </div>
                        </div>
                    </div>
                        <div className="card3">
                            <div className="card2-head">
                                <strong >SPEAKER BENEFITS</strong>
                            </div>
                            <div className="info">
                                <ol>
                                    <li>Free Conference Pass </li>
                                    <li>Access to networking Party</li>
                                    <li>1 night stay (On Conference Date) - <br/> only applicable if travelling from other state</li>
                                    <li>Lunch and High tea</li>
                                    <li>Apreciation Letter</li>
                                    <li>Speaker Goodies</li>
                                </ol>
                            </div>
                            <div className="card2-head">
                                <strong >Durations</strong>
                            </div>
                            <div className="info">
                                <span>We have two options available</span>
                                <ol>
                                    <li>30 min (25min talk + 5min QnA) </li>
                                    <li>45min (40min talk + 5min QnA)</li>
                                </ol>
                            </div>
                        </div>
                        <div className="card3">
                            <div className="card2-head">
                                <strong >Topics we are looking for(includes but not limited to)</strong>
                            </div>
                            <div className="info">
                                <ol>
                                    <li>Data Forensics and Incident Response (DFIR) </li>
                                    <li>Malware Analysis/Reverse Engineering</li>
                                    <li>Cyber Threat Intelligence</li>
                                    <li>Vulnerability Research & Disclosure</li>
                                    <li>Network/Cloud/Endpoint Security</li>
                                    <li>Mobile/Portable Device Security</li>
                                    <li>IoT/Embedded Device Security</li>
                                    <li>Biometrics Hacking</li>
                                    <li>Application/Web Application Security</li>
                                    <li>Red/Blue/Purple Teaming</li>
                                    <li>SCADA/ICS/OT Security</li>
                                    <li>SecOps/DevSecOps</li>
                                    <li>Exploit Development</li>
                                    <li>Other..</li>
                                </ol>
                            </div>
                        </div>
                        {/* <div className="card3">
                            <div className="info">
                                <ol start={7}>
                                <li>IoT/Embedded Device Security</li>
                                    <li>Biometrics Hacking</li>
                                    <li>Application/Web Application Security</li>
                                    <li>Red/Blue/Purple Teaming</li>
                                    <li>SCADA/ICS/OT Security</li>
                                    <li>SecOps/DevSecOps</li>
                                    <li>Exploit Development</li>
                                    <li>Other..</li>
                                </ol>
                            </div>
                        </div> */}
                        {/* <div className="card3">
                            <div className="card2-head">
                                <strong >Durations</strong>
                            </div>
                            <div className="info">
                                <span>We have two options available</span>
                                <ol>
                                    <li>30 min (25min talk + 5min QnA) </li>
                                    <li>45min (40min talk + 5min QnA)</li>
                                </ol>
                            </div>
                        </div> */}
                    </div>

                    <p style={{marginTop:"4rem" , padding:"2rem"}}>
    <strong style={{color:"#e56c61"}}>Please Note: </strong> 
    <ul>
        <li>Speakers presenting at BSides Mumbai who bring additional co-speaker/speakers for their talk are responsible for their co-speaker's accommodation and any other expenses.</li>
    </ul>
    <li>While filling the CFP form try to be more specific in terms of detailing on topic/abstract/or any other field.
    For any query please contact us at </li>
    <span>Email : <a href="mailto:info@bsidesmumbai.in" className="email">info@bsidesmumbai.in</a></span>
   
</p>

                    <div className="cfp-main">
                        <Link href="https://forms.gle/vQEyTXnuVjVxzka9A" target="_blank" className="cfp-btn">
                            <strong>Submit Your Paper</strong>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CFP;