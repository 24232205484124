import React from "react";
import Card from "./Card";


const Team = () =>{

    const team = [
        {
            id: "mahadev_gavas",
            name: "Mahadev Gavas",
            role: "Organizer",
            imgURL: require("./images/mahadev.jpg"),
            linkedin: "https://www.linkedin.com/in/mahadev-gavas",
        },
        // {
        //     id: "abhisar_pandey",
        //     name: "Abhisar Pandey",
        //     role: "Co-organizer",
        //     imgURL: require("./images/Abhisar-Pandey.jpeg"),
        //     linkedin: "https://www.linkedin.com/in/imabhisarpandey/",
        // },
        {
            id: "sameep_shukla",
            name: "Sameep Shukla",
            role: "Developer",
            imgURL: require("./images/sameep.jpg"),
            linkedin: "https://in.linkedin.com/in/sameepshukla",
        },
        {
            id: "pratik_badgujar",
            name: "Pratik Badgujar",
            role: "Developer",
            imgURL: require("./images/pratikbadgujar.jpg"),
            linkedin: "https://www.linkedin.com/in/pratik-badgujar-277a841b0",
        },
        {
            id: "akash",
            name: "Akash Patil",
            role: "Logistics Coordinator",
            imgURL: require("./images/akash.jpg"),
            linkedin: "https://www.linkedin.com/in/akashpatil98",
        },
        {
            id: "dhanshree",
            name: "Dhanshree Patil",
            role: "Social Media Manager",
            imgURL: require("./images/dhanashree.jpg"),
            // linkedin: "",
        },
        {
            id: "prashant_bhavasar",
            name: "Prashant Bhavsar",
            role: "UI/UX Designer",
            imgURL: require("./images/prashant.jpg"),
            linkedin: "https://www.linkedin.com/in/prashant-bhavsar-3o12",
        },
        {
            id: "pritam",
            name: "Pritam Tare",
            role: "Event Coordinator",
            imgURL: require("./images/pritam.jpg"),
            linkedin: "https://www.linkedin.com/in/pritam-tare",
        },
        {
            id: "kiran",
            name: "Kirankumar Subuddi",
            role: "Event Coordinator",
            imgURL: require("./images/kiran.jpg"),
            linkedin: "https://in.linkedin.com/in/kirankumar-subuddi",
        },
        {
            id: "akshita_bhatnagar",
            name: "Akshita Bhatnagar",
            role: "Social Media Manager",
            imgURL: require("./images/akshitabhatnagar.jpg"),
            linkedin: "https://www.linkedin.com/in/akshitaabhatnagar",
        }
    ]

    return (
        <div className="window-main">
            <div className="window-sp">
                <div className="window-content">
                    <p>
                    Behind the scenes of <strong style={{color:"#e56c61"}}>BSides Mumbai</strong>, a dedicated group of professionals in the field of Information Security is driving the event. Organized by the community, for the community, our team is committed to bringing this global event to Mumbai. We are grateful for the support of our sponsors, volunteers, community supporters, speakers, and delegates, as well as the participation of individuals like you.
                    </p>
                    <h2 className="subhead">Our Team</h2>
                    <div className="team-card">
                        {team.map((member) => {
                            return <Card key={member.id} {...member}/>
                        })}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Team;