import React, { useState } from "react";
import "../css/faq.css";


const faqs = [
  {
    question: "What is BSides and what is its history?",
    answer: "BSides is a community-driven framework for organizing information security conferences. It provides opportunities for individuals to present and participate in discussions on various topics related to information security. The first BSides conference was held in 2009 in Las Vegas, as an alternative to the larger DEF CON conference. For additional details, you can find more information on Wikipedia or the official website of Security BSides (bsides.org).",
  },

  {
    question: "What is the mission of BSides Mumbai?",
    answer: "Our mission is to bring together students, enthusiasts, and professionals from the region to learn, connect, and grow in the field of information security. BSides Mumbai aims to be a welcoming space where experienced professionals and newcomers alike can meet local companies and leaders in the industry.",
  },

  {
    question: "What is the registration process and fees/payment?",
    answer: "The registration process is straightforward. On the home screen, you'll find a \"Register Now\" button. Clicking this button will take you to the MeraEvent website, where you'll need to fill out all the required details and proceed to payment. Once your payment is confirmed, you'll receive your tickets and confirmation over the registered email. The registration process is facilitated through MeraEvent, utilizing a 100% advance payment model.",
  },

  {
    question: "What is the fees/payment structure?",
    answer: "The fees/payment structure comprises different ticket tiers with varied prices, considering factors such as early bird discounts, student rates, group bookings, etc. All rates quoted excludes GST, with additional charges for payment gateway services. It's important to note that costs associated with travel and accommodation are not included in the ticket fee and are the participants responsibility for reservation and payment.",
  },

  {
    question: "What is the venue policy?",
    answer: "Attendees will be notified about venue details either while purchasing the ticket or a different mail will be role out prior to 30days of the event. Also, The organizer retains the right to modify the event program, timing, or location. Such changes, if necessary, will not substantially diminish the benefits described for the participant. Attendees will be promptly informed of changes at least one month before the event date.",
  },

  {
    question: "What is the group booking policy?",
    answer: "7% discount will be applied to registrations for groups of 5 or more participants.",
  },

  {
    question: "What is the privacy policy?",
    answer: (
      <>
        <p>Registration grants the organizer the right to save and process personal data for accounting, billing, and future event notifications. All the information of participant won't be shared with any of the partner/sponsor by BSides Mumbai Team.</p>
        <p>BSides utilizes third-party services for website hosting, ticketing, and analysis. Participants are encouraged to refer to the appropriate third-party privacy policies for complete information. These third-party services include:</p>
        <ul>
          <li>Vercel</li>
          <li>GitHub</li>
          <li>MeraEvents</li>
          <li>Google Forms</li>
          <li>Google Sheets</li>
        </ul>
      </>
    ),
  },
 
  {
    question: "BSides Mumbai Code of Conduct?",
    answer: (
      <>
        <p>At BSides Mumbai, we want to thank all our participants for helping us keep the event friendly and respectful for everyone. To make sure everyone feels welcome and safe, we've come up with some simple rules:</p>
        <ol>
          <ul><span className="highlight">1.Respect Everyone:</span> Treat everyone with respect, no matter who they are. Be kind and considerate to everyone you meet.</ul>
          <ul><span className="highlight">2.No Harassment Allowed:</span> We don't tolerate any kind of bullying, teasing, or making people feel uncomfortable. This includes things like saying mean things, touching someone when they don't want you to, or bothering them online.</ul>
          <ul><span className="highlight">3.Take Care of Things:</span> If you accidentally break something, it's your responsibility to fix or replace it. Let's all be careful with the stuff around us.</ul>
          <ul><span className="highlight">4.Tell Us if Something's Wrong:</span> If you see someone doing something they shouldn't, or if you feel uncomfortable, let one of the organizers know right away. We'll take care of it without making a fuss. (rather than post about it online / after the event).</ul>
          <ul><span className="highlight">5.Consequences for Breaking the Rules:</span> If you break these rules, we might have to ask you to leave the event. Nobody wants that, so let's all follow the rules and have a good time together.</ul>
          
        </ol>
        <p>By coming to BSides Mumbai, you're agreeing to follow these rules and help us make the event a fun and safe place for everyone. Thanks for being awesome!. Conference participants violating these simple rules may be sanctioned or expelled from the conference without a refund at the sole discretion of the conference organizers.</p>
      </>
    ),
  },
  
  {
    question: "What is the resume Dropbox and how can attendees utilize it?",
    answer: "The resume dropbox is a feature where attendees can submit their resumes for potential job opportunities or networking purposes. Please note that resumes submitted will be shared with sponsors and partners for job opportunities. The resume dropbox will be available at the venue. To submit your resume, simply scan the QR code provided at venue and upload it.",
  },

  {
    question: "How can I help BSides Mumbai? Individual/Partner/Sponsor?",
    answer: (
      <>
        <p>At BSides Mumbai, we want to thank all our participants for helping us keep the event friendly and respectful for everyone. To make sure everyone feels welcome and safe, we've come up with some simple rules:</p>
        <ol>
          <ul><span className="highlight">Individually:</span>Support our event while adhering to our Terms & Conditions and Code of Conduct. Spread the word about our event before and after it takes place, both on social media and in person, to help interested individuals discover us. Unofficially suggest designs for BSides Mumbai stickers, badges, and t-shirts by emailing them to bsidesmumbai@gmail.com. Connect with others by sharing your thoughts and ideas within the network, and by asking interesting questions to speakers and panelists during the event.</ul>
          <ul><span className="highlight">Partner/Sponsor:</span> For sponsorship details, contact us at sponsorship@bsidesmumbai.in or fill out the Google form with all the necessary information. You can also find the sponsorship deck on the link provided below:</ul> 
          <ul><span className="highlight">http://gg.gg/sponsorshipkit</span></ul> 

        </ol>
      </>
    ),
  },

  {
    question: "Does BSides Mumbai pay for travel and accommodation for speakers?",
    answer: "Although we would have liked to cover the travel expenses of our valuable speakers, unfortunately, we cannot afford to do so at the moment. However, we do offer a one-night stay (on the conference date) for speakers traveling from other states. Depending on our sponsorship availability, we may attempt to provide travel reimbursement. We extend our advance thanks to speakers from different state of India or foreign countries who will be attending BSides Mumbai.",
  },

  {
    question: "Is there any minimum age to participate?",
    answer: "Nope, there is no minimum age requirement. However, to attend and fully engage in the conference, it is recommended to have a basic understanding of communication and some knowledge of information security, or to be enthusiastic about learning and starting your journey from BSides Mumbai.",
  },

  {
    question: "Will the talks be in English?",
    answer: "Yes, we prefer to conduct all talks in English. However, there might be instances where a mix of Hindi and English = \"Hinglish\" is used to maintain the Mumbai vibe. This ensures that knowledge sharing remains accessible to all attendees, as English serves as a common language for communication.",
  },

  {
    question: "How can I contact the BSides Mumbai team for concerns or any information?",
    answer: (
      <>
        <p>If you have any concerns or need information, you can reach out to us using the following email addresses:</p>
        <ol>
          <ul><span className="highlight">info@bsidesmumbai.in </span>For any kind of information.</ul>
          <ul><span className="highlight">sponsorship@bsidesmumbai.in </span> For any kind of partnership/sponsorship</ul> 
          <ul><span className="highlight">admin@bsidesmumbai.in </span> For any kind of escalation, complaint, or administrative task</ul> 
          <ul><span className="highlight">contact@bsidesmumbai.in </span>  For any other inquiry</ul> 

        </ol>
        <p>Please note that responses may be delayed and could occur outside of normal working hours. We are a team of volunteers, and we need to coordinate BSides Mumbai work around our day jobs. We apologize for any delays and appreciate your patience!</p>
        <p>Please note that responses may be delayed and could occur outside of normal working hours. We are a team of volunteers, and we need to coordinate BSides Mumbai work around our day jobs. We apologize for any delays and appreciate your patience!</p>
        Additionally, please ensure that <span className="highlight">contact@bsidesmumbai.in </span> is kept in CC, as this email is accessible by most of the core team members.
      </>
    ),
  },   
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="window-main">
    <div className="window-sp">
    <div className="faq-container">
    <h2 className="subhead">FAQ's</h2>
      {faqs.map((faq, index) => (
        <div key={index} className={`faq-active`}>
          <button
            className="faq-question"
            onClick={() => toggleFAQ(index)}
          >
            {faq.question}
          </button>
          <div className="faq-answer">
            {/* C */}
            {typeof faq.answer === "string" ? <p>{faq.answer}</p> : faq.answer}
          </div>
        </div>
      ))}
    </div>
    </div>
    </div>

  );
};

export default FAQ;