import React from "react";
import '../css/getin.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import '../css/Header.css';
import logo from './images/bsidesmumbailogo.png';

const Getintouch = () =>{

    const customIcon = () =>{
        return(
                <ion-icon style={{width:"2rem", height:"2.5rem"}} name="logo-discord"></ion-icon>

        );
    }
    
    const socials = [
        {
            id: "linkedin",
            name: "Linkedin",
            link: "https://in.linkedin.com/company/bsidesmumbai"
        },
        {
            id: "instagram",
            name: "Instagarm",
            link: "https://www.instagram.com/bsidesmumbai/"
        },
        {            
            id: "twitter",
            name: "X [Twitter]",
            link: "https://twitter.com/BSidesMumbai"
        },
        {            
            id: "whatsapp",
            name: "WhatsApp",
            link: "https://chat.whatsapp.com/FOLa4NT7zHQ7AoDx4CgwEa",
            icon: <WhatsAppIcon/>
        },
        {            
            id: "discord",
            name: "Discord",
            link: "https://discord.gg/2KRGQWBGR3",
            icon: customIcon()
        }
    ];

    return(
        <div className="window-main">
            <div className="window-wc">
                <div className="window-content-social">
                <h2 className="subhead">Our Socials</h2>
                <div className="allcard">
                    <div className="card-social">
                        <div className="card-text">
                            <a className="linkedin" href={socials[0].link} target="_blank" rel="noreferrer">
                                <LinkedInIcon/>
                                <p className="name">{socials[0].name}</p>
                            </a>
                        </div>
                    </div>
                    <div className="card-social">
                        <div className="card-text">
                            <a className="linkedin" href={socials[1].link} target="_blank" rel="noreferrer">
                                <InstagramIcon/>
                                <p className="name">{socials[1].name}</p>
                            </a>
                        </div>
                    </div>
                    <div className="card-social">
                        <div className="card-text">
                            <a className="linkedin" href={socials[2].link} target="_blank" rel="noreferrer">
                                <XIcon/>
                                <p className="name">{socials[2].name}</p>
                            </a>
                        </div>
                    </div>
                    <div className="card-social">
                        <div className="card-text">
                            <a className="linkedin" href={socials[3].link} target="_blank" rel="noreferrer">
                                <WhatsAppIcon/>
                                <p className="name">{socials[3].name}</p>
                            </a>
                        </div>
                    </div>
                    <div className="card-social">
                        <div className="card-text">
                            <a className="linkedin" href={socials[4].link} target="_blank" rel="noreferrer">
                                {customIcon()}
                                <p className="name">{socials[4].name}</p>
                            </a>
                        </div>
                    </div>
                </div>
                <h2 className="subhead">Contact us </h2>
                <div className="getin-cont">
                    <img src={logo} alt="BSides Mumbai" id="top" className="header-logo" />
                    <div className="getin-cont-text">
                        <p>www.bsidesmumbai.in</p>
                        <p><span>Email : <a href="mailto:info@bsidesmumbai.in">info@bsidesmumbai.in</a></span></p>
                        <p><span>Phone : <a href="tel:+91 9082799611">+91   9082799611</a></span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Getintouch;