import React from "react";
import '../css/speaker.css';
import { Link } from "react-router-dom";
import speaker1 from "./images/speakers/sanchay.png";
import speaker2 from "./images/speakers/Adhokshaj.png";
import speaker3 from "./images/speakers/ravirajput.png";
import speaker4 from "./images/speakers/SagartiwariI.png";
import speaker5 from "./images/speakers/SHUBHAM.png";
import speaker6 from "./images/speakers/Aakash.png";
import speaker7 from "./images/speakers/Sankalp.png";
import speaker8 from "./images/speakers/PALAK.png";
import speaker9 from "./images/speakers/farhad.png";
import speaker10 from "./images/speakers/GAURAV.png";
import speaker11 from "./images/speakers/ravi.png";
import speaker12 from "./images/speakers/PavanK.png";
import speaker13 from "./images/speakers/AbishekMatt.jpg";
import speaker14 from "./images/speakers/Shashi.png";










import CallMadeIcon from '@mui/icons-material/ArrowOutward';;

const Speaker = () =>{

    // const speaker_details = [
    //     {
    //         id: "xx",
    //         name: "Xy Zy",
    //         role:"cc",
    //         imgURL: require("./images/unknown-person-icon-27.jpg")
    //     },
    //     {
    //         id: "xx",
    //         name: "Xy Zy",
    //         role:"cc",
    //         imgURL: require("./images/unknown-person-icon-27.jpg")
    //     },
    //     {
    //         id: "xx",
    //         name: "Xy Zy",
    //         role:"cc",
    //         imgURL: require("./images/unknown-person-icon-27.jpg")
    //     },
    //     {
    //         id: "xx",
    //         name: "Xy Zy",
    //         role:"cc",
    //         imgURL: require("./images/unknown-person-icon-27.jpg")
    //     }
    // ]

    // const [isFlipped, setIsFlipped] = useState(false);
    // const cardClass = isFlipped ? 'flipped' : '';

    // const handleFlip = () => {
    //     setIsFlipped(!isFlipped);
    // };

    return(
        <div className="window-main">
            <div className="window-sp">
                <div className="speaker-content">
                    {/* <div style={{padding:"2rem"}}> */}
                    <div>
                        <h1>Our Keynote Speaker</h1>
                    </div>

                    <div className="speaker-card">

                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Ravi Burlagadda</h1>
                                    <p>Sr Vice President<br/>Information security at Jio Platforms Limited</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker11} alt="speaker1"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                    <p>Keynote Speaker</p>
                                </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/raviburlagadda/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div>
                        <h1>Our Speakers</h1>
                    </div>
                    <div className="speaker-card">

                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Pavan Karthick</h1>
                                    <p>Threat Researcher III<br/>CloudSek</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker12} alt="speaker12"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                    <p>Threat Intel Strategies Against <br/> Malware Threats</p>
                                </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/e11i0t/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>



                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Abhishek Mathew</h1>
                                    <p>Threat Intelligence Researcher<br/>CloudSek</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker13} alt="speaker13"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                <p>Threat Intel Strategies Against <br/> Malware Threats</p>
                                </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/sh4d0wdr4g0n/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>


                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Shashi Prasad</h1>
                                    <p>Security Consultant and Red Team Lead<br/>Redfox Security</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker14} alt="speaker14"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                    <p>Pwning IPv6 Networks: The Infamous IPv6 DNS Takeover Attack</p>
                                </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/cybersecshashi/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>



                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Ravi Rajput</h1>
                                    <p>Manager <br/> Reaktr.ai</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker3} alt="speaker1"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                    <p>V2X Exploitation:<br/>  Steering Through Auto Cyber Seas</p>
                                </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/frustratedresearcher/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>


                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Sagar Tiwari</h1>
                                    <p>Security Researcher</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker4} alt="speaker4"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                    <div className="speaker-topic">
                                        <p>OSINT Tracking: <br/>  The subtle art of not giving a f*ck</p>
                                    </div>
                                    <div className="speaker-con-btn">
                                        <Link target="_blank" to="https://www.linkedin.com/in/sagar--tiwari--/">Connect </Link>
                                        <div className="speaker-con-icon">
                                            <CallMadeIcon/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                       

                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Shubham Kumar</h1>
                                    <p>Senior Information Security Analyst  <br/> Transunion</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker5} alt="speaker5"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                    <div className="speaker-topic">
                                        <p>OSINT Tracking: <br/>  The subtle art of not giving a f*ck</p>
                                    </div>
                                    <div className="speaker-con-btn">
                                        <Link target="_blank" to="https://www.linkedin.com/in/shubham--kumar--/">Connect </Link>
                                        <div className="speaker-con-icon">
                                            <CallMadeIcon/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Aakash Kharade</h1>
                                    <p>Security Consultant <br/> Accorian</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker6} alt="speaker6"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                        <p>ChatGPT  ASST. Hacking: Pentesting Roku apps for fun and profit!</p>
                                    </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/aakash-kharade-527108134/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Sankalp Paranjpe</h1>
                                    <p>Security Researcher</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker7} alt="speaker7"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                        <p>Introduction to AWS: Serverless <br/> exploitation </p>
                                    </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/sankalp-s-paranjpe/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>



                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Farhad Barbhuiya</h1>
                                    <p>Product Security Engineer<br/> Honeywell</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker9} alt="speaker9"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                        <p>MacOS Security: DYLD_INSERT_LIBRARIES Injection Challenges</p>
                                    </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/farhad-sajid-barbhuiya/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>



                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Palak Bansal</h1>
                                    <p>Cyber Security Engineer<br/> Honeywell</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker8} alt="speaker8"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                        <p>MacOS Security: DYLD_INSERT_LIBRARIES Injection Challenges</p>
                                    </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/palak-bansal-0xff/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                <div className="speaker-content">
                        <div>
                        <h1>Workshop Speakers</h1>
                        </div>

                    <div className="speaker-card">


                    <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Sanchay Singh</h1>
                                    <p>Founder and Lead Trainer<br/> HackersVilla Cybersecurity</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker1} alt="speaker1"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                    <p>VideoGame Hacking: <br/>  Pentesting into Modern Day Games</p>
                                </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="http://linkedin.com/in/sanchayofficial">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                       

                        <div className="speaker-card-main">
                            <div className="front">
                                <div className="speaker-card-header">
                                    <h1>Gaurav Gogia</h1>
                                    <p>Security Engineer <br/> Qualys</p>
                                </div>
                                <div className="speaker-img-con">
                                    <div className="speaker-img">
                                        <img src={speaker10} alt="speaker10"/>
                                    </div>
                                </div>
                                <div className="speaker-con">
                                <div className="speaker-topic">
                                        <p>Forensics: <br/> Digging <br/>them out!</p>
                                    </div>
                                        <div className="speaker-con-btn">
                                            <Link target="_blank" to="https://www.linkedin.com/in/gaurav-gogia/">Connect </Link>
                                            <div className="speaker-con-icon">
                                                <CallMadeIcon/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                        
                      

                        </div>
                </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Speaker;