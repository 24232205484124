import React from "react";
import abtimg from './images/abt.svg';
import '../css/about.css';
import { Link } from "react-router-dom";

const About = () =>{
    return(
        <div className="about-main">
            <div className="about-window">
                <div>
                    <h1 className="subhead">About the Conference</h1>
                </div>
                <div className="about-content">
                    <img src={abtimg} alt="BSides Mumbai" loading="lazy"/>
                    <p>
                    <span>BSides Mumbai</span> A community driven information security conference that provides a platform for cybersecurity enthusiasts to share knowledge, learn new skills, and connect with like-minded professionals. Our goal is to promote a culture of collaboration and open communication in the field of cybersecurity, and to foster the growth of the security community in Mumbai and beyond.
                    <div className="stat-container">
                    <p class="stat"><span class="stat-value">1</span><span class="stat-tag">Day Event</span></p>
                    <p class="stat"><span class="stat-value">10</span><span class="stat-tag">Speakers</span></p>
                    <p class="stat"><span class="venuep">Venue&nbsp;</span><Link to="/venue" className="venuelink">Check here</Link></p>
                    </div>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;