import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Card = ({name, role, id, imgURL, linkedin , icon}) =>{
    return (
            <div className="card">
                <img className="team-img" src={imgURL} alt={id} loading="lazy"/>
                <div className="card-text">
                    <p className="name">{name}
                        <p className="role">[{role}]</p>
                    </p>
                    <a className="linkedin" href={linkedin} target="_blank" rel="noreferrer">
                    <LinkedInIcon/>
                    </a>
                </div>
            </div>
    );
}

export default Card;