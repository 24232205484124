import React from 'react';
import '../css/countdown.css'
import CountDownCard from './CountDownCard';

const CountDownTimer = ({ targetDate }) => {
  
    const ExpiredNotice = () => {
        return (
          <div className="expired-notice">
            <p>Event is LIVE !!</p>
          </div>
        );
      };

    // const [days, hours, minutes, seconds] = UseCountDown(targetDate);
    // console.log(days);
    if (targetDate[0] + targetDate[1] + targetDate[2] + targetDate[3] <= 0) {
        return <ExpiredNotice />;
      } else {
            return (
                <div className="countdown__container">
                <CountDownCard
                    label="days"
                    number={targetDate[0]}
                />
                <CountDownCard
                    label="hours"
                    number={targetDate[1]}
                />
                <CountDownCard
                    label="minutes"
                    number={targetDate[2]}
                />
                <CountDownCard
                    label="seconds"
                    number={targetDate[3]}
                />
                </div>
            );
        }
};

export default CountDownTimer;