import { Link } from "react-router-dom";
import '../css/Header.css';
import logo from './images/bsidesmumbailogo.png';

const Header = () =>{

    return(
        <div className="header-container">
            <header>
            <a href="/">
                <img src={logo} alt="BSidesMumbai" id="top" className="header-logo" />
            </a>
            <Link to="/" id="contactus" className="contactus-btn">
                HOME
            </Link>
            </header>
        </div>
    );
}

export default Header;