import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import '../css/Footer.css'

const Footer = () =>{
    useEffect(() => {
        Array.from(document.getElementsByClassName("nav-btn")).forEach((btn) => {
          btn.addEventListener("click", (e) => {
            document.getElementsByClassName("active")[0].classList.remove("active");
            if (e.target.classList[0] === "nav-btn-text") {
              e.target.parentElement.classList.add("active");
            } else {
              e.target.classList.add("active");
            }
          });
        });
      });
    
      return (
        <div className="nav-container" id="nc">
          <div className="nav">
            
            <Link to="/schedule" id="about-btn" className="nav-btn active">
              <p className="nav-btn-text">Schedule</p>
            </Link>
            <Link to="/team" id="team-btn" className="nav-btn">
              <p className="nav-btn-text">Team</p>
            </Link>
            <Link to="/speaker" id="faq-btn" className="nav-btn">
              <p className="nav-btn-text">Speakers</p>
            </Link>
            <Link to="/trainings" id="start-btn" className="nav-btn">
              <p className="nav-btn-text">Trainings</p>
            </Link>

            <Link to="/sponsors" id="event-btn" className="nav-btn">
              <p className="nav-btn-text">sponsors</p>
            </Link>
            <Link to="/faq" id="coc-btn" className="nav-btn">
              <p className="nav-btn-text">faq</p>
            </Link>
          </div>
        </div>
      );
}

export default Footer;