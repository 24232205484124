import React from 'react';
import '../css/schedule.css';

const Schedule = () => {
    const scheduleItems = [
        { time: '08:30AM - 09:30AM', duration: '90 min', mainHall: 'Registration & Breakfast', room1: '', colspan: 2 },
        { time: '09:30AM - 10:00AM', duration: '90 min', mainHall: 'Opening Note + Deep Prajwalan', room1: '', colspan: 2 },
        { time: '10:00AM - 10:30AM', duration: '30 min', mainHall: 'Ravi Prakash: Keynote ', room1: '', colspan: 2 },
        { time: '10:30AM - 11:15am', duration: '45 min', mainHall: 'Pavan Karthick and Abhishek Mathew - Threat Intelligence Strategies Against Malware Threats', room1: 'Sanchay Singh: Video Game Hacking - Pentesting into modern day games', rowspan: 3 },
        { time: '11:15AM - 11:45AM', duration: '30 min', mainHall: 'Aakash Kharade: ChatGPT Assisted Hacking - Pentesting Roku Apps', room1: '', rowspan: 0 }, 
        { time: '11:45AM - 12:30PM', duration: '45 min', mainHall: 'Shashi Prasad: IPv6 DNS Takeover Attack', room1: '', rowspan: 0 }, 
        { time: '12:30PM - 01:00PM', duration: '30 min', mainHall: 'Sankalp Paranjpe: Introduction to AWS Serverless Exploitation', room1: '' },
        { time: '01:00PM - 02:00PM', duration: '60 min', mainHall: 'Lunch and Networking', room1: '', colspan: 2 },
        { time: '02:00PM - 02:45PM', duration: '45 min', mainHall: 'Sagar Tiwari and Shubham Sharma: OSINT Tracking - The subtle art of not giving a f*ck', room1: 'Gaurav Gogia:  Digital Forensics - Digging \'em out', rowspan: 2 },
        { time: '02:45PM - 03:15PM', duration: '30 min', mainHall: 'Ravi Rajput: V2X Exploitation - Steering through auto cyber seas', room1: '', rowspan: 0 }, 
        { time: '03:15PM - 04:00PM', duration: '45 min', mainHall: 'Farhad Barbhuiya and Palak Bansal: MacOS Security - DYLD_INSERT_LIBRARIES Injection Challenges', room1: '' },
        { time: '04:00PM - 04:30PM', duration: '45 min', mainHall: 'High Tea', room1: '', colspan: 2 },
        { time: '04:30PM - 05:15PM', duration: '45 min', mainHall: 'Speaker Panel Discussion: Is there a skills shortage in cybersecurity, how can we bridge the gap between job seekers and opportunities?', room1: '', colspan: 2 },
        { time: '05:15PM - 06:00PM', duration: '45 min', mainHall: 'Speaker Panel Discussion: How secure are our trusted companies Google, Microsoft, Apple, Etc?', room1: '', colspan: 2 },
        { time: '06:00PM - 06:30PM', duration: '30 min', mainHall: 'Award, Momento and Closing Note', room1: '', colspan: 2 },
        { time: '08:30PM - 12:30AM', duration: '120 min', mainHall: 'After Party', room1: '', colspan: 2 }
    ];

    return (
        <div className="window-main">
            <div className="window-container">
                <h2 className="subhead">Event Schedule</h2>
                <table>
                    <thead>
                        <tr>
                            {/* <th>Duration</th> */}
                            <th>Time</th>
                            <th>Main Hall</th>
                            <th>Room 1</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scheduleItems.map((item, index) => (
                            <tr key={index}>
                                {/* <td>{item.duration}</td> */}
                                <td>{item.time}</td>
                                {item.colspan ? (
                                    <td colSpan={item.colspan}>{item.mainHall}</td>
                                ) : (
                                    <>
                                        <td>{item.mainHall}</td>
                                        {item.rowspan === 0 ? null : item.rowspan ? (
                                            <td rowSpan={item.rowspan}>{item.room1}</td>
                                        ) : (
                                            <td>{item.room1}</td>
                                        )}
                                    </>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Schedule;
