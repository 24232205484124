import React from 'react'

const Socials = ({id, name , link , icon}) => {
  return (
    <div className="sociallink">
        <ul>
            <li>                    
                <a className="linkedin" href={link} target="_blank" rel="noreferrer">
                    <div>
                        {icon}
                    </div>
                </a>
            </li>
        </ul>
    </div>
  )
}

export default Socials;