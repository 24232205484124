import React from 'react'
import '../css/venue.css'
import venue from './images/venue.jpeg';

const Venue = () => {
  return (
    <div className="window-main">
    <div className="window-sp">
        <div className="window-content">

            <h2 className="subhead">Venue</h2>

            <div className='address'>
                <div className='addr-line'>
                 Sheila Gopal Raheja Auditorium, 505,Bandra West, Mumbai, Maharashtra 400050
                </div>
                <div className='addr-map'>
                <img src={venue} alt='venue'/>
                    <iframe title='googlelocation' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9966800848892!2d72.83080457524999!3d19.063883582138235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c913fb1b8707%3A0x433d82983299673!2sSheila%20Gopal%20Raheja%20Auditorium!5e0!3m2!1sen!2sin!4v1713087761530!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Venue;