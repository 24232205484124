import React from 'react';
import "../css/trainings.css";
import '../css/speaker.css';
import sponsortrain from "./images/sponsors/redfox-logo.svg";
import speaker4 from "./images/speakers/SagartiwariI.png";
import CallMadeIcon from '@mui/icons-material/ArrowOutward';
import { Link } from "react-router-dom";


const Trainings = () => {
  return (
    <div className="window-main">
      <div className="window-sp">
        <div className="training-container">
          <h2 className="subhead">Trainings</h2>
          <div className="training-logo">
            <div className="logo-circle">
              {/* <img src="./images/sponsors/redfox" alt="Training Logo" /> */}
              <img src={sponsortrain} alt="sponsortrain1"/>
            </div>
            <div className="register-button">
            <button onClick={() => window.location.href = 'https://www.meraevents.com/event/BSidesMumbai'}>Register Now</button>
          </div>
          </div>


                        
          <div className="training-content">
            <div className="training-section left">
              <h3 className="training-title">Web Hacking Advanced Training </h3>
              <p className="training-details"><strong>Format:</strong> 2-Days Training</p>
                                 
                                    <p className="training-details"><strong>Training Time:</strong> 9:00am to 5:00pm</p>

                       


                        

                                    <p className="training-details"><strong>Trainer Bio:</strong> Will be updated</p>
                                    <p className="training-details"><strong>Course Description:</strong> In this intensive two-day journey, participants will dive into the dynamic world of web application security, focusing on advanced techniques for exploiting and securing web-based environments. The course combines theoretical insights with extensive hands-on practice, enabling attendees to uncover, exploit, and mitigate vulnerabilities within web applications. From reconnaissance and vulnerability exploitation to post-exploitation and privilege escalation, participants will gain practical experience in a variety of attacks such as SQL injection, Cross-Site Scripting, Server-Side Request Forgery, HTTP Parameter Pollution, Insecure Direct Object Reference, Carriage Return and Line Feed and more. This course is designed to provide a deep understanding of how to protect web applications from advanced threats, ensuring preparedness for real-world challenges.</p>
                                      

                                  <p className="training-details"><strong>Target Audience:</strong></p>
                                  
                                      <li className="training-details">Aspiring Web Application Penetration Testers</li>
                                      <li className="training-details">Cybersecurity Professionals with a Focus on Web Security</li>
                                      <li className="training-details">Security Analysts Specializing in Web Applications</li>
                                      <li className="training-details">IT Professionals Looking to Specialize in Web Application Security</li>
                                  
                                  <p className="training-details"><strong>Prerequisites:</strong> Basic understanding of web application security concepts is recommended. No prior experience with advanced exploitation techniques is required.</p>
                                  <p className="training-details"><strong>What attendees need to bring:</strong> Attendees are required to bring their laptops (min 8GB RAM) with administrative privileges and a pre-installed virtual machine software (e.g., VirtualBox, VMware).</p>
                                  <p className="training-details"><strong>Price:</strong> ₹21,999 </p>
                                  <p className="training-details"><strong> Registration Deadline:</strong> 22/05/2024</p>
             
            </div>
            <div className="vertical-line"></div>
            <div className="training-section right">
              <h3 className="training-title">Windows Red Teaming: Breach Sim Training</h3>
              <p className="training-details"><strong>Format:</strong> 2-Days Training</p>
                                 
                                    <p className="training-details"><strong>Training Time:</strong> 9:00am to 5:00pm</p>
                                    <p className="training-details"><strong>Trainer Bio:</strong> Will be updated</p>
                                    <p className="training-details"><strong>Course Description:</strong>In this comprehensive 2-day course, participants will delve into the intricate world of Active Directory (AD) security and exploitation. Through a blend of theoretical lectures and hands-on exercises, attendees will gain practical skills in identifying, mitigating, and exploiting vulnerabilities within AD environments.</p>

                                  <p className="training-details"><strong>Target Audience:</strong></p>
                                  
                                      <li className="training-details">Cybersecurity professionals</li>
                                      <li className="training-details">Security analysts</li>
                                      <li className="training-details">System administrators</li>
                                      <li className="training-details">Penetration testers</li>
                                      <li className="training-details">Red team enthusiasts</li>
                                  
                                  <p className="training-details"><strong>Prerequisites:</strong>Participants should have a basic understanding of Active Directory and cybersecurity concepts. No specific prerequisites are required, but familiarity with networking and security fundamentals would be beneficial.</p>
                                  <p className="training-details"><strong>What attendees need to bring:</strong> Attendees are required to bring their laptops (min 8GB RAM) with administrative privileges and a pre-installed virtual machine software (e.g., VirtualBox, VMware).</p>
                                  <p className="training-details"><strong>Price:</strong> ₹27,999 </p>
                                  <p className="training-details"><strong> Registration Deadline:</strong> 22/05/2024</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Trainings;

